
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseCard from "@/components/BaseCard.vue";
import BaseCloseModal from "@/components/BaseCloseModal.vue";
import BaseButton from "@/components/BaseButton.vue";

type docTypesDTO = "pdf";
type audioTypesDTO = "mp3" | "m4a" | "wav";
type videoTypesDTO = "mkv" | "mp4" | "wmv" | "avi";
type mediaTypesDTO = docTypesDTO | audioTypesDTO | videoTypesDTO;

type mediaBoxDTO =
  | HTMLEmbedElement
  | HTMLAudioElement
  | HTMLVideoElement
  | HTMLDivElement
  | null;

@Component({
  components: {
    BaseCard,
    BaseCloseModal,
    BaseButton,
  },
})
export default class DialogAttachmentMedia extends Vue {
  @Prop() url!: string;
  @Prop() fileName!: string;

  fileUrl = "";
  mediaType = "";

  mounted(): void {
    this.refresh();
  }

  onCloseClicked(): void {
    this.$modal.hide("dialog-attachment-media");
  }

  downloadFile(download = true): void {
    if (this.mediaType || download) {
      const link = document.createElement("a");
      link.href = this.fileUrl;
      if (download) link.download = this.fileName;
      link.target = "_blank";
      link.click();
    } else {
      this.$swal.fire({
        text: "Unsupported file type.",
        icon: "warning",
      });
    }
  }

  getMediaBox(type: string): mediaBoxDTO {
    const docTypes = ["pdf"];
    const audioTypes = ["mp3", "m4a", "wav"];
    const videoTypes = ["mkv", "mp4", "wmv", "avi"];

    let mediaBox;
    if (docTypes.includes(type)) {
      mediaBox = document.querySelector(".media-doc") as HTMLEmbedElement;
      mediaBox.type = this.mediaType;
      document.querySelector(".media-content")?.classList.add("small");
    } else if (audioTypes.includes(type)) {
      mediaBox = document.querySelector(".media-audio") as HTMLAudioElement;
    } else if (videoTypes.includes(type)) {
      mediaBox = document.querySelector(".media-video") as HTMLVideoElement;
      document.querySelector(".media-content")?.classList.add("small");
    } else {
      return document.querySelector(".media-unsupported") as HTMLDivElement;
    }

    mediaBox.src = this.fileUrl;
    return mediaBox;
  }

  async refresh(): Promise<void> {
    const { data } = await this.$api.get(this.url, { responseType: "blob" });

    const mediaTypes = {
      pdf: "application/pdf",
      mp3: "audio/mpeg",
      m4a: "audio/x-m4a",
      wav: "audio/wav",
      mkv: "vídeo/x-matroska",
      mp4: "video/mp4",
      wmv: "video/x-ms-wmv",
      avi: "video/x-msvideo",
    };

    const fileType: mediaTypesDTO = this.fileName
      .replace(/.+\.(.+)/g, "$1")
      .toLowerCase() as mediaTypesDTO;

    this.mediaType = mediaTypes[fileType];

    const blob = new Blob([data], { type: this.mediaType });
    this.fileUrl = window.URL.createObjectURL(blob);

    const container = this.getMediaBox(fileType);
    if (container) container.classList.add("show");

    // URL.revokeObjectURL(file);
  }
}
