const arrayMothByPosition = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const mapAttachmentTypeToIconeName = {
  VIDEO_LINK: "video-link.svg",
  AUDIO_LINK: "audio-link.svg",
  LINK: "link.svg",
  DOCUMENT_FILE: "doc-file.svg",
  VIDEO_FILE: "video-file.svg",
  AUDIO_FILE: "audio-file.svg",
};

export { arrayMothByPosition, mapAttachmentTypeToIconeName };
