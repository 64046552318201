import { render, staticRenderFns } from "./BaseCloseModal.vue?vue&type=template&id=71ee18c8&scoped=true"
import script from "./BaseCloseModal.vue?vue&type=script&lang=ts"
export * from "./BaseCloseModal.vue?vue&type=script&lang=ts"
import style0 from "./BaseCloseModal.vue?vue&type=style&index=0&id=71ee18c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ee18c8",
  null
  
)

export default component.exports