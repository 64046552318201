
import { Component, Prop, Vue } from "vue-property-decorator";
import DialogAttachmentMedia from "@/components/DialogAttachmentMedia.vue";
import { BrainLibraryAttachmentDTO } from "@/interfaces/BrainLibrary.dto";
import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";
import { mapAttachmentTypeToIconeName } from "@/utils/Constants";

type mapAttachmentTypeToIconeNameTypes =
  | "VIDEO_LINK"
  | "AUDIO_LINK"
  | "LINK"
  | "DOCUMENT_FILE"
  | "VIDEO_FILE"
  | "AUDIO_FILE";

@Component
export default class AttachmentIcon extends Vue {
  @Prop() philosophy?: boolean;

  @Prop() library!: BrainLibraryDTO;
  @Prop() attachment!: BrainLibraryAttachmentDTO;
  @Prop() attachmentIndex!: number;

  openNewWindowAttachmentIconsTypes = ["VIDEO_LINK", "AUDIO_LINK", "LINK"];

  downloadDocumentAttachmentIconsTypes = [
    "DOCUMENT_FILE",
    "VIDEO_FILE",
    "AUDIO_FILE",
  ];

  get isUrl(): boolean {
    return this.openNewWindowAttachmentIconsTypes.includes(
      this.attachment.type
    );
  }

  get isFile(): boolean {
    return this.downloadDocumentAttachmentIconsTypes.includes(
      this.attachment.type
    );
  }

  get iconName(): string {
    return mapAttachmentTypeToIconeName[
      this.attachment.type as mapAttachmentTypeToIconeNameTypes
    ];
  }

  get tooltipText(): string {
    const label = this.attachment.label;
    const sublabel = this.attachment.sublabel;

    if (this.isFile) {
      if (label && sublabel) return `${label} | ${sublabel?.toUpperCase()}`;
      else if (label) return label;
      else if (sublabel) return sublabel?.toUpperCase();
    }

    if (this.isUrl) return sublabel ? `Password: ${sublabel}` : label;

    return "";
  }

  onIconClick(): void {
    if (this.isUrl) window.open(this.attachment.label, "_blank");
    else if (this.isFile)
      this.dialogAttachment(
        this.library._id,
        this.attachmentIndex,
        this.attachment.file.originalName
      );
  }

  dialogAttachment(
    libraryId: string,
    attachmentIndex: number,
    fileName: string
  ): void {
    try {
      const urlPost = `/library/library-access/download/${libraryId}/${attachmentIndex}`;
      const urlPhilosophy = `/library/investment-philosophy/download/${attachmentIndex}`;
      const url = this.philosophy ? urlPhilosophy : urlPost;
      this.showDialogAttachmentMedia(url, fileName);
    } catch (error) {
      console.error(error);
    }
  }

  showDialogAttachmentMedia(url: string, fileName: string): void {
    const innerWidth = window.innerWidth;

    const dialogWidth =
      innerWidth < 600
        ? 450
        : innerWidth < 750
        ? 600
        : innerWidth < 1050
        ? 750
        : 1050;

    if (innerWidth < 450) {
      this.downloadFile(url, fileName);
    } else {
      this.$modal.show(
        DialogAttachmentMedia,
        { url, fileName },
        {
          name: "dialog-attachment-media",
          height: "auto",
          width: dialogWidth,
        }
      );
    }
  }

  async downloadFile(url: string, fileName: string): Promise<void> {
    const { data } = await this.$api.get(url, { responseType: "blob" });
    const blob = new Blob([data]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.target = "_blank";
    link.click();
  }
}
